import {createAction, props} from '@ngrx/store';
import {TAccountModel} from "@revolve-app/app/core/metadata/types/account.models";


//actions for accounts store
export const ACCOUNT_LIST_REQUEST_START_ACTION = '[ACCOUNT] list dto';
export const ACCOUNT_LIST_REQUEST_SUCCESS_ACTION = '[ACCOUNT] list success';

export const ACCOUNT_LIST_FILTER_UPDATE = '[ACCOUNT] list filter update';
export const ACCOUNT_LIST_FILTER_RESET = '[ACCOUNT] list filter reset';
export const ACCOUNT_LIST_PAGINATION_UPDATE = '[ACCOUNT] list pagination update';

export const ACCOUNT_GET_BY_ID_REQUEST = '[ACCOUNT] get by id dto start';
export const ACCOUNT_GET_BY_ID_SUCCESS = '[ACCOUNT] get by id dto success';


export const ACCOUNT_UPDATE_REQUEST_START = '[ACCOUNT] update dto start';
export const ACCOUNT_UPDATE_REQUEST_SUCCESS = '[ACCOUNT] update dto success';
export const ACCOUNT_UPDATE_REQUEST_FAIL = '[ACCOUNT] update dto fail';


export const ACCOUNT_CREATE_REQUEST_START = '[ACCOUNT] create dto start';
export const ACCOUNT_CREATE_REQUEST_SUCCESS = '[ACCOUNT] create dto success';
export const ACCOUNT_CREATE_REQUEST_FAIL = '[ACCOUNT] create dto fail';


export const ACCOUNT_DELETE_REQUEST_START = '[ACCOUNT] delete dto start';
export const ACCOUNT_DELETE_REQUEST_SUCCESS = '[ACCOUNT] delete dto success';
export const ACCOUNT_DELETE_REQUEST_FAIL = '[ACCOUNT] delete dto fail';

export const ON_SOCKET_INITIAL_INFO = '[ACCOUNT] social initial info';

//be-fe-metadata for accounts store
export interface AccountFilters {
  email?: string
}

export const accountListRequestStart = createAction(
  ACCOUNT_LIST_REQUEST_START_ACTION

);
export const accountListRequestSuccess = createAction(
  ACCOUNT_LIST_REQUEST_SUCCESS_ACTION,
  props<{payload: any}>()
);
export const accountListFilterUpdate = createAction(
  ACCOUNT_LIST_FILTER_UPDATE,
  props<{payload: any}>()
);
export const accountListFilterReset = createAction(
  ACCOUNT_LIST_FILTER_RESET
);
export const accountListPaginationUpdate = createAction(
  ACCOUNT_LIST_PAGINATION_UPDATE,
  props<{payload: any}>()

);
export const accountGetByIdSuccess = createAction(
  ACCOUNT_GET_BY_ID_SUCCESS,
  props<{payload: TAccountModel}>()
);
export const accountGetByIdRequest = createAction(
  ACCOUNT_GET_BY_ID_REQUEST,
  props<{id: string}>()
);

export const accountUpdateRequest = createAction(
  ACCOUNT_UPDATE_REQUEST_START,
  props<{id: string, payload: any, redirectUrl: string}>()
);
export const accountCreateRequestStart = createAction(
  ACCOUNT_CREATE_REQUEST_START,
  props<{payload: any, redirectUrl: string}>()
);



export const accountDeleteRequest = createAction(
  ACCOUNT_DELETE_REQUEST_START,
  props<{id: string}>()
);
export const accountDeleteRequestSuccess = createAction(
  ACCOUNT_DELETE_REQUEST_SUCCESS
);
export const accountUpdateRequestSuccess = createAction(
  ACCOUNT_UPDATE_REQUEST_SUCCESS
);
export const accountCreateRequestSuccess = createAction(
  ACCOUNT_CREATE_REQUEST_SUCCESS
);
export const accountDeleteRequestFail = createAction(
  ACCOUNT_DELETE_REQUEST_FAIL
);
export const accountUpdateRequestFail = createAction(
  ACCOUNT_UPDATE_REQUEST_FAIL
);
export const accountCreateRequestFail = createAction(
  ACCOUNT_CREATE_REQUEST_FAIL
);



